var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{staticClass:"px-0",attrs:{"displayTabs":_vm.isFilterOpen},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-icon',{staticClass:"mr-2",on:{"click":_vm.dashboardLink}},[_vm._v(" mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.hasMemberOrgAccess ? "Nye påmeldinger" : "Nye påmeldinger og søknader")+" ")]},proxy:true},{key:"actions",fn:function(){return [(_vm.selectedRows.length > 0)?_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","data-cy":"markAsReadBtn"},on:{"click":function($event){return _vm.markAsRead(_vm.selectedRows)}}},[_vm._v(" Marker som sett ")]):_vm._e(),_c('v-btn',{attrs:{"color":"white","outlined":""},on:{"click":function($event){_vm.isFilterOpen = !_vm.isFilterOpen}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_vm._v(" Filter ")],1)]},proxy:true},{key:"tabs",fn:function(){return [(!_vm.currentGroupBy)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.currentGroupBy = 'courseName'}}},[_vm._v(" Grupper etter kurs ")]):_vm._e(),(_vm.currentGroupBy)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.currentGroupBy = null}}},[_vm._v(" Fjern gruppering ")]):_vm._e()]},proxy:true}])},[[_c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.newApplications,"group-by":_vm.currentGroupBy,"sort-by":['courseId', 'applicationSentDate'],"item-key":"id","show-select":!_vm.hasMemberOrgAccess},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatLocalizedDateP(value))+" ")]}},{key:"item.applicationSentDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatLocalizedDateP(value))+" ")]}},{key:"item.enrollmentDeadline",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatLocalizedDateP(value))+" ")]}},{key:"item.appliedViaForm",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Søkt gjennom web" : "Lagt til av kursadmin")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","to":_vm.getParticipantsLink(item.courseId)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Vis deltakerliste")])])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }